import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

function UserInfoScreen() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [formErrors, setFormErrors] = useState('');
  const errors = {};
  const responsiveConditions = {};
  const pathname = `${window.location.pathname}`;
  const pathnameTakeaway = pathname.replace(/[^/]*$/, 'takeaway'); 

  if (pathname.includes('walmart')) {
    responsiveConditions.Id = 26;
  } else if (pathname.includes('kroger')) {
    responsiveConditions.Id = 27;
  } else if (pathname.includes('ht')) {
    responsiveConditions.Id = 25;
  } else {
    responsiveConditions.Id = 29;
  }

  const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  };
  
  const validateEmail = (values) => {
    if (!values) {
      errors.email = 'Email Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values)) {
      errors.email = 'Invalid email address';
    } else {
      errors.email = false;
    }
  
    // console.log(errors);
    setFormErrors(errors);
    return errors;
  };
  
  const validatePhone = (values) => {  
    if (!values) {
      errors.phone = 'Phone Required';
    } else if (!(values.match('[0-9]{10}'))) {
      errors.phone = 'Please enter a complete Phone Number';
    } else {
      errors.phone = false;
    }
  
    // console.log(errors);
    setFormErrors(errors);
    return errors;
  };

  const submitForm = (event) => {
    event.preventDefault();
  
    const formDataSet = new FormData(event.target);
    const phoneData = formDataSet.get('phone_number');
    const emailData = formDataSet.get('email');

    const validateEmailThing = validateEmail(emailData);
    const validatePhoneThing = validatePhone(phoneData);

    if (!validatePhoneThing.phone) {
      formDataSet.set('phone_number', phoneData);
    } else {
      console.log('phoneinvalid');
      // console.log(formData);
  
      return;
    }
  
    if (!validateEmailThing.email) {
      formDataSet.set('email', emailData);
    } else {
      console.log('emailinvalid');
      // console.log(formData);
  
      return;
    }

    // console.log('formErrors');
    // console.log(formErrors);

    if (validateEmailThing.email && validatePhoneThing.phone) {
      // formDataSet.set('firstname', 'empty');
      // formDataSet.set('lastname', 'empty');
      
      // const blobImage = dataURItoBlob(state.imageUri);
      // formDataSet.set(state.imageName, blobImage);
      
      // console.log(formDataSet);

      // return;
    }

    // const headers = new Headers();
    // headers.append('Target-URL', 'https://neomvplive.mvpdevtest.net');
    // headers.append('Access-Control-Allow-Origin', '*');

    // const blobImage = dataURItoBlob(state.imageUri);
    // formDataSet.set('imageName', state.imageName);
    // formDataSet.set('imageBuffer', blobImage);
  
    const requestOptions = {
      method: 'POST',
      // headers,
      body: formDataSet,
    };
    
    // https://httpbin.org/anything For Smoketest 
    // https://mvp.live/endpoint/be88117e-9402-450a-aa47-69e889972917
    // https://neomvplive.mvpdevtest.net/api/v1/interactive_register/37/
    // Temp working https://192.168.0.246:3010/api/v1/interactive_register/37/

    const requestDomain = 'https://neomvplive.mvpdevtest.net/api/v1/interactive_register/37/';

    console.log(requestOptions.body);

    if (requestOptions.body) {
      fetch(requestDomain, requestOptions)
        .then((response) => {
          console.log('Submit Response');
    
          return response.json();
        })
        .then((dataProfile) => {
          console.log('dataProfile Response');
          // console.log(dataProfile);

          if (dataProfile.url) {
            const formDataFollowUpSet = new FormData();
            const blobImage = dataURItoBlob(state.imageUri);
            formDataFollowUpSet.set('image', blobImage, 'MICROSITE_PREVIEW_URL');
            // For Proxys
            // const correctedURL = dataProfile.url.replace('https://neomvplive.mvpdevtest.net', requestDomain);

            const requestFollowUpOptions = {
              method: 'POST',
              // headers,
              body: formDataFollowUpSet,
            };

            fetch(dataProfile.url, requestFollowUpOptions)
              .then((response) => {
                console.log('Image Response');
                // console.log(response);
          
                return response.json();
              })
              .then((dataImage) => {
                console.log('dataImage Response');
                // console.log(dataImage);

                if (dataImage.msg === 'Data saved successfully') {
                  const sendToParticipantURL = `${dataProfile.url}interactive/${responsiveConditions.Id}/send`;

                  const requestFollowUpSendOptions = {
                    method: 'POST',
                    // headers,
                    body: {},
                  };

                  // Another call? For send. 
                  fetch(sendToParticipantURL, requestFollowUpSendOptions)
                    .then((response) => {
                      console.log('Send Message Response');
                      // console.log(response);
                
                      return response.json();
                    })
                    .then((dataSend) => {
                      console.log('dataSend Message Response');
                      console.log(dataSend);

                      // Error: Please check the Interactive or Participant ID
                      if (dataSend.msg === 'Email send successfully' 
                      || dataSend.msg === 'SMS send successfully! Please check' 
                      || dataSend.msg === 'SMS and Email send successfully! Please check') {
                        navigate(pathnameTakeaway);
                      // Separate these out for more indepth error tracking.
                      // else if (dataSend.msg === 'SMS send successfully! Please check') {
                      // dataSend.msg === 'Email send successfully' 
                      // || dataSend.msg === 'SMS send successfully! Please check'
                      } else {
                        // UNABLE TO SEND MESSAGE
                      }
                    });
                } else {
                  // ISSUE SAVING IMAGE
                }
              });
          } else if (dataProfile.status === 0) {
            // Previous API
            navigate(pathnameTakeaway);
          } else {
            // NO INITIAL RESPONSE FROM API
          }
        });  
    } else {
      // EMPTY FORM
    }
  };

  return (
    <div className="App__inner">
      <form 
        onSubmit={submitForm}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <p
          className="text__large-header">
          Try new
          <br /> 
          Coca-Cola® Spiced
          <br />
          in stores
        </p>
        <p
          className="text__mid-header">
          Enter details to receive
          <br />
          your meme
        </p>
        <TextField
          color="primary"
          label="Email*"
          id="email" 
          name="email"
          error={!formErrors.email}
          helperText={!formErrors.email ? '' : formErrors.email}
          sx={{
            marginBottom: '15px',
            width: '80%',
          }} />
        <TextField
          color="primary"
          label="Phone*"
          id="phone_number" 
          name="phone_number"
          error={!formErrors.phone}
          helperText={!formErrors.phone ? '' : formErrors.phone}
          sx={{
            marginBottom: '15px',
            width: '80%',
          }} />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{
            width: '80%',
            height: '7%',
            borderRadius: 8,
            textTransform: 'none',
            fontSize: '14.45px',
            lineHeight: '45px',
            fontWeight: 700,
          }}>
          Tap to 
          <br />
          Send
        </Button>
      </form>
    </div>
  );
}

export default UserInfoScreen;
