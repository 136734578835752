import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import IdleScreen from './routes/Idle-Screen';
import AdScreen from './routes/Ad-Screen';
// import SelectProScreen from './routes/Select-Pro-Screen';
import CaptureScreen from './routes/Capture-Screen';
import ReviewScreen from './routes/Review-Info-Screen';
import TakeawayScreen from './routes/Takeaway-Screen';
import UserInfoScreen from './routes/User-Info-Screen';
import RedirectScreen from './routes/Redirect-Screen';

function AppRouter() {
  const hostname = `${window.location.hostname}`;
  const pathname = `${window.location.pathname}`;
  const allOriginURLs = ['', 'walmart', 'kroger', 'ht', 'ibotta', 'coca-cola-spiced-web'];
  const allPageURLs = ['', 'idle', 'ad', 'capture', 'review', 'userinfo', 'takeaway'];

  // react-router-dom Route not working with arrays, regex, anything... 
  // This really needs to be cleaner, WTH router-dom
  // Heeey use an array and map!
  // Almost perfect but variable element names is a nogo
  return (
    <BrowserRouter>
      { allOriginURLs.map((originURL, originIndex) => (
        <Routes>
          { allPageURLs.map((pageURL, pageIndex) => (
            <Route
              key={`${originURL}/${pageURL}`}
              path={`${originURL}/${pageURL}`}
              element={(pageURL === '') ? (
                <IdleScreen />
              ) : (pageURL === 'idle') ? (
                <IdleScreen />
              ) : (pageURL === 'ad') ? (
                <AdScreen />
              ) : (pageURL === 'capture') ? (
                <CaptureScreen />
              ) : (pageURL === 'review') ? (
                <ReviewScreen />
              ) : (pageURL === 'userinfo') ? (
                <UserInfoScreen />
              ) : (pageURL === 'takeaway') ? (
                <TakeawayScreen hostname={hostname} pathname={pathname} />
              ) : ( 
                <IdleScreen />
              )} />
          ))}
        </Routes>
      ))}
    </BrowserRouter>
  );
}

export default AppRouter;
