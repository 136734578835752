import * as React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import backgroundImg from '../../images/background.png';
import imagePromptDesktop from '../../images/ccs-hero.jpg';

function TakeawayScreen({ hostname, pathname }) {
  const navigate = useNavigate();
  const start = () => navigate('/');
  const changingElement = {};

  // console.log(hostname);
  // console.log(pathname);

  if (pathname.includes('walmart')) {
    changingElement.URL = 'http://ibotta.com/home/offer-tag/CokeSpicedQ12024';
    changingElement.text = 'Tap to recieve my coupon';
  } else if (pathname.includes('kroger')) {
    changingElement.URL = 'https://www.kroger.com/savings/c/800000022910';
    changingElement.text = 'Tap to recieve my coupon';
  } else if (pathname.includes('ht')) {
    changingElement.URL = 'https://www.harristeeter.com/savings/c/800000090443';
    changingElement.text = 'Tap to recieve my coupon';
  } else {
    changingElement.URL = '';
    changingElement.text = '';
  }

  return (
    <div className="App__inner">
      <div>
        <h2
          className="txt__select-header"
          style={{
            fontSize: '62px',
            top: '0px',
            paddingTop: '100px',
            paddingBottom: '20px',
          }}
        >
          THANK
          <br />
          YOU!
        </h2>

        { changingElement.URL 
          ? ( 
            <p>
              <a 
                href={changingElement.URL}
                className="btn__coupon" 
              >
                {changingElement.text}
              </a>
            </p> 
          )
          : ( 
            ''
          )}

        <img 
          src={imagePromptDesktop} 
          className="image__cover cover-image__idlescreen" 
          alt="prompt__desktop" 
          style={{
            paddingTop: '0px',
          }}
        />
      </div>
    </div>
  );
}

export default TakeawayScreen;
