import React, { useEffect, useState } from 'react';
import {
  Typography,
  Button,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ImagePreview } from '../../hooks/ImagePreview';
// import headerImage from '../../images/Header_PWAP_Large.png';

function ReviewInfoScreen() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [dataUri, setDataUri] = useState('');
  const [proSelection, setProSelection] = useState('');
  const dateNow = Date.now();
  const filenameOfImage = `pose_${dateNow}.jpg`;

  useEffect(() => {
    const { imageDataUri, proSelect } = state;
    setProSelection(proSelect);
    setDataUri(imageDataUri);
  });

  let pathnameTryAgain = `${window.location.pathname}`;
  pathnameTryAgain = pathnameTryAgain.replace(/[^/]*$/, 'capture');

  const tryAgain = () => navigate(pathnameTryAgain, { state: { proSelect: proSelection } });

  const urlToObject = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const file = new File([blob], filenameOfImage, { type: blob.type });
    return file;
  };

  const handleShareError = (message) => {
    console.log(message);
  };

  async function saveWebShare({ text, files: filesUrl }) {
    // let files;

    // filesUrl = [];
    // filesUrl.push(dataUri);

    // Test compatibility
    /* if (navigator.share === undefined) {
      handleShareError('Unsupported share feature test');
      
      const link = document.createElement('a');
      link.download = filenameOfImage;
      link.href = dataUri;
      link.click();

      return;
    } */  

    let pathnameUserInfo = `${window.location.pathname}`;
    pathnameUserInfo = pathnameUserInfo.replace(/[^/]*$/, 'userinfo');

    navigate(pathnameUserInfo, { state: { imageUri: dataUri, imageName: filenameOfImage } });
  }

  return (
    <div className="App__inner">
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '30px',
      }}>
        <Typography
          color="#fff"
          sx={{
            textTransform: 'uppercase',
            fontSize: '27.79px',
            lineHeight: '26.4px',
            fontWeight: 700,
            marginTop: '15px',
            marginBottom: '15px',
          }}>
          Look good?
        </Typography>
      </div>
      <ImagePreview
        dataUri={dataUri}
        style={{
          height: '70%',
        }} />

      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '3%',
      }}>
        <Button
          variant="outlined"
          color="primary"
          className="button__tryagain-button"
          onTouchEnd={tryAgain}
          sx={{
            borderRadius: 8,
            textTransform: 'none',
            fontSize: '25px',
            lineHeight: '30px',
            fontWeight: 700,
            width: '45%',
            marginRight: '5%',
            paddingBottom: '10px',
          }}>
          Try again
        </Button>
        <Button
          onClick={() => saveWebShare('snapapro', dataUri)}
          variant="contained"
          color="primary"
          className="button__looksgood-button"
          sx={{
            borderRadius: 8,
            textTransform: 'none',
            fontSize: '25px',
            lineHeight: '30px',
            fontWeight: 700,
            width: '45%',
            paddingBottom: '10px',
          }}>
          Looks Good!
        </Button>
      </div>
    </div>
  );
}

export default ReviewInfoScreen;

/* Header Image Example
<div style={{
  height: '0px',
  position: 'relative',
  'z-index': '9000',
}}>
  <img
    src={headerImage}
    alt=""
    style={{
      position: '',
      height: '100px',
    }}
  />
</div> */
